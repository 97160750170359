import io from 'socket.io-client';
import '../css/index.scss';

const connectionAlert = document.getElementById('connectionAlert');
const menus = document.getElementById('menus');

let rootMenu;
let lastMenus;

function show(dom) {
    dom.classList.add('show');
    dom.classList.remove('hide');
}

function hide(dom) {
    dom.classList.remove('show');
    dom.classList.add('hide');
}

function isLocal(hostname) {
    return hostname.endsWith('localhost') || hostname === '127.0.0.1';
}

function scheme(hostname) {
    if (isLocal(hostname)) {
        return 'http';
    }
    return 'https';
}

function port(hostname) {
    if (isLocal(hostname)) {
        return ':5000';
    }
    return '';
}

const hostname = document.getElementById('hostname')
    .getAttribute('data-hostname');

const nav = io.connect(`${scheme(hostname)}://${hostname}${port(hostname)}/nav`);
const connection = {
    active: false,

    connect() {
        this.active = true;
        hide(connectionAlert);
    },

    disconnect() {
        this.active = false;
        show(connectionAlert);
    },
};

nav.on('connect', connection.connect);
nav.on('disconnect', connection.disconnect);

const builder = {
    button(prototype, parent) {
        const button = document.createElement('div');

        button.id = prototype.name;
        button.className = 'btn';
        button.innerHTML = `<p>${prototype.text}</p>`;
        button.disabled = !prototype.enabled;

        const result = {
            elem: button,
        };

        if (prototype.action === null) {
            return result;
        }

        switch (prototype.action.kind) {
        case 'menu': {
            const menu = prototype.action.event;
            result.action = builder.menu(menu, parent);

            button.addEventListener('click', () => {
                show(result.action.elem);
                hide(lastMenus[lastMenus.length - 1].elem);
                lastMenus.push(result.action);
            });
            break;
        }
        case 'page': {
            button.addEventListener('click', () => {
                window.location.href = prototype.action.event;
            });
            break;
        }
        default:
        }

        return result;
    },

    menu(prototype, parent) {
        const root = document.createElement('div');

        root.id = prototype.id;
        root.classList.add('menu-style', 'hide');
        root.innerHTML = `<div class="game-header"><h1>${prototype.title}</h1></div>`;

        if (parent !== undefined) {
            const navButtons = document.createElement('div');
            navButtons.className = 'nav-buttons';

            const back = document.createElement('button');
            navButtons.appendChild(back);
            back.innerHTML = '<i class="fa fa-chevron-left" aria-hidden="true"></i> Back';
            back.addEventListener('click', () => {
                if (lastMenus.length < 2) {
                    return;
                }

                show(lastMenus[lastMenus.length - 2].elem);
                hide(lastMenus[lastMenus.length - 1].elem);
                lastMenus.pop();
            });

            const home = document.createElement('button');
            navButtons.appendChild(home);
            home.innerHTML = '<i class="fa fa-home" aria-hidden="true"></i> Home';
            home.addEventListener('click', () => {
                show(rootMenu.elem);
                hide(lastMenus[lastMenus.length - 1].elem);
                lastMenus = [rootMenu];
            });

            root.appendChild(navButtons);
        }

        const btnGroup = document.createElement('div');
        btnGroup.className = 'btn-wrap';
        root.appendChild(btnGroup);

        const result = {
            elem: root,
            parent,
            buttons: [],
        };

        prototype.buttons.forEach((b) => {
            const button = builder.button(b, result);
            result.buttons.push(button);
            btnGroup.appendChild(button.elem);
        });

        menus.appendChild(root);

        return result;
    },
};

function update(menu) {
    menus.innerHTML = '';
    const newMenu = builder.menu(menu);
    show(newMenu.elem);

    rootMenu = newMenu;
    lastMenus = [rootMenu];
}

nav.on('update', update);
